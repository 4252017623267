import React, { FC, useCallback, useMemo } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  styled,
  TextField,
  Typography,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { AccountStatus } from '../../../types';
import {
  accountsSelectors,
  accountsSlice,
  FilterAccountsBy,
  filterAccountsOptions,
  getUniqueFilterLists,
  selectAccountsPageFilters,
  SortAccountsBy,
  sortAccountsByOptions,
} from '../../../redux/accounts';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import CustomDatePicker from '../../../components/datePicker/CustomDatePicker';

const StyledDrawer = styled(Drawer)`
  min-width: 388px;
  max-width: 400px;
  border-radius: 0px;

  .MuiDrawer-paper {
    min-width: 388px;
    max-width: 400px;
    border-radius: 0px;
  }
`;

const getFilterAccountsByList = (
  filterBy: FilterAccountsBy,
  uniqueFilterLists: {
    pdms: {
      value: string;
      label: string;
    }[];
    dms: {
      value: string;
      label: string;
    }[];
    cps: {
      value: string;
      label: string;
    }[];
  },
) => {
  if (filterBy === FilterAccountsBy.PortfolioDeliveryManager)
    return uniqueFilterLists.pdms;
  if (filterBy === FilterAccountsBy.DeliveryManager)
    return uniqueFilterLists.dms;

  return uniqueFilterLists.cps;
};

type FilterDrawerProps = {
  isOpen: boolean;
  onClose: () => void;
  resetFilters: () => void;
  isFiltersActive: boolean;
};

const ALL_AT_ONCE = 'All at once';
export const FilterDrawer: FC<FilterDrawerProps> = ({
  isOpen,
  onClose,
  resetFilters,
  isFiltersActive,
}) => {
  const dispatch = useAppDispatch();
  const uniqueFilterLists = useAppSelector(getUniqueFilterLists);
  const accountsPageFilters = useAppSelector(selectAccountsPageFilters);
  const filterBy = accountsPageFilters.filterBy;
  const filterValues = accountsPageFilters.filterValues;
  const dateFilterValueLocal = useAppSelector(
    accountsSelectors.getDateFilterValueLocal,
  );
  const sortBy = useAppSelector(accountsSelectors.getSortBy);

  const filterAccountsByList = useMemo(() => {
    return getFilterAccountsByList(filterBy, uniqueFilterLists);
  }, [filterBy, uniqueFilterLists]);

  const onChangeFilterBy = useCallback(
    (event: SelectChangeEvent<string>) => {
      dispatch(
        accountsSlice.actions.setAccountsPageFilters({
          filterBy: event.target.value as FilterAccountsBy,
        }),
      );
    },
    [dispatch],
  );

  const onChangeFilterValue = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const value = event.target.value as string;

      dispatch(
        accountsSlice.actions.setAccountsPageFilters({
          filterValues:
            // On autofill we get a stringified value.
            typeof value === 'string' ? value.split(',') : value,
        }),
      );
    },
    [dispatch],
  );

  const handleSortByChoose = useCallback(
    (
      event: React.SyntheticEvent<Element, Event>,
      value: {
        label: SortAccountsBy;
        value: SortAccountsBy;
      } | null,
    ) => {
      dispatch(
        accountsSlice.actions.setSortBy({
          sortBy: value?.value ?? SortAccountsBy.Margin,
        }),
      );
    },
    [dispatch],
  );

  const onDeleteFilterValue = useCallback(
    (value: string) => {
      dispatch(
        accountsSlice.actions.setAccountsPageFilters({
          filterValues: filterValues.filter((x) => x !== value),
        }),
      );
    },
    [dispatch, filterValues],
  );

  return (
    <StyledDrawer anchor="right" open={isOpen} keepMounted onClose={onClose}>
      <DialogTitle style={{ display: 'flex', gap: 12 }}>
        Filters{' '}
        {isFiltersActive && (
          <Button
            variant="text"
            qa-id="dropdown-menu-button"
            onClick={resetFilters}
          >
            <RemoveCircleOutlineIcon fill="#448aff" />
            &nbsp;Reset all
          </Button>
        )}
      </DialogTitle>
      <IconButton
        aria-label="close"
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
        }}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
      <Grid container padding={2}>
        <Grid item xs={12} marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id="fiterbyfieldlabel">Filter by</InputLabel>
            <Select
              labelId="fiterbyfieldlabel"
              value={filterBy}
              label="Filter by"
              onChange={onChangeFilterBy}
            >
              {filterAccountsOptions.map((item) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id="filterValuelabel">Select {filterBy}</InputLabel>
            <Select
              labelId="filterValuelabel"
              multiple
              renderValue={(selected) => (
                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                  {selected.map((value) => {
                    return (
                      <Chip
                        key={value}
                        onDelete={() => {
                          onDeleteFilterValue(value);
                          getFilterAccountsByList(filterBy, uniqueFilterLists);
                        }}
                        onMouseDown={(event) => {
                          event.stopPropagation();
                        }}
                        label={
                          filterAccountsByList.find((x) => x.value === value)
                            ?.label || 'Name not found'
                        }
                      />
                    );
                  })}
                </Box>
              )}
              value={filterValues}
              label={`Select ${filterBy}`}
              onChange={onChangeFilterValue}
            >
              {filterAccountsByList.map((item: any) => (
                <MenuItem key={item.value} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        {/* Date picker button */}
        <Grid item xs={12} marginTop={2}>
          <CustomDatePicker
            setDateFilter={{
              type: 'dispatch',
              func: accountsSlice.actions.setDateFilterValueLocal,
              setGlobalFunc: accountsSlice.actions.setDateFilterValue,
              clean: accountsSlice.actions.cleanDateFilterValues,
            }}
            dateFilterValueLocal={dateFilterValueLocal}
            inputView={true}
          />
        </Grid>
        {/* Status filtering */}
        <Grid item xs={12} marginTop={2}>
          <FormControl fullWidth>
            <InputLabel id="Status">Status</InputLabel>
            <Select
              labelId="Status"
              value={
                accountsPageFilters.accountStatus === null
                  ? ALL_AT_ONCE
                  : accountsPageFilters.accountStatus
              }
              label="Status"
              onChange={(e) => {
                dispatch(
                  accountsSlice.actions.setAccountsPageFilters({
                    accountStatus:
                      e.target.value === ALL_AT_ONCE
                        ? null
                        : (e.target.value as AccountStatus),
                  }),
                );
              }}
            >
              {Object.values(AccountStatus).map((status) => (
                <MenuItem key={status} value={status}>
                  {status}
                </MenuItem>
              ))}
              <Divider component={'li'} />
              <MenuItem value={ALL_AT_ONCE}>{ALL_AT_ONCE}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        {/* Sorting */}
        <Grid item xs={12} marginTop={2}>
          <Autocomplete
            sx={{
              '.MuiInputBase-adornedStart': { padding: '9px' },
              '& .MuiChip-root': { borderRadius: 4 },
            }}
            options={sortAccountsByOptions}
            onChange={handleSortByChoose}
            value={{ label: sortBy, value: sortBy }}
            renderOption={(props, option) => (
              <Box component="li" {...props}>
                <Box component="div" width="100%">
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <Typography
                      component="p"
                      sx={{ fontSize: '14px', color: 'text.primary' }}
                    >
                      {option.value}
                    </Typography>
                  </Stack>
                </Box>
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                name="sortBy"
                label="Sorting"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: <>{params.InputProps.endAdornment}</>,
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                placeholder="Please select"
              />
            )}
          />
        </Grid>
      </Grid>
    </StyledDrawer>
  );
};
