import { Components } from '@mui/material/styles/components';
import { Theme, alpha } from '@mui/material';
import * as themeScale from './variables';

import InterVariableFont from './../assets/fonts/Inter.woff2';

export default <Components<Theme>>{
  MuiCssBaseline: {
    styleOverrides: `
      @font-face {
        font-family: 'Inter var';
        font-style: normal;
        font-display: swap;
        font-weight: 100 900;
        src: url(${InterVariableFont}) format('woff2');
        font-named-instance: 'Regular',
      }
      html {
        -webkit-font-smoothing: auto;
      }

      *::-webkit-scrollbar {
        background-color: rgba(0, 0, 0, 0);
        width: 8px;
        height: 8px;
      }
      *::-webkit-scrollbar-thumb {
        background-color: #a0a0a5;
        border-radius: 16px;
      
        &:hover {
          background-color: #a0a0a5;
        }
      }
      *::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0);
      
        &:hover {
          background-color: rgba(0, 0, 0, 0);
        }
      }
      *::-webkit-scrollbar-button {
        display: none;
      }
      *::-webkit-scrollbar-corner {
        background-color: rgba(0, 0, 0, 0);
      }
    `,
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
    },
    styleOverrides: {
      root: {
        textTransform: 'none',
      },
      contained: ({ theme }) => ({
        '&.contrast, &.contrast:hover': {
          backgroundColor: theme.palette.common.white,
          color: theme.palette.primary.main,
        },
        '&.contrast.Mui-disabled ': {
          backgroundColor: alpha(theme.palette.common.white, 0.8),
          color: theme.palette.text.disabled,
        },
      }),
      outlined: ({ theme }) => ({
        '&.contrast, &.contrast:hover': {
          borderColor: theme.palette.common.white,
          color: theme.palette.common.white,
        },
        '&.contrast.Mui-disabled ': {
          borderColor: alpha(theme.palette.common.white, 0.6),
          color: alpha(theme.palette.common.white, 0.6),
        },
      }),
      text: ({ theme }) => ({
        padding: '5px 15px',
        '&.MuiButton-textPrimary': {
          background: theme.palette.action.hover,
        },
        '&.MuiButton-textError': {
          background: alpha(theme.palette.error.main, 0.08),
        },
        '&.Mui-disabled': {
          background: theme.palette.background.default,
        },
        '&.contrast, &.contrast:hover': {
          color: theme.palette.common.white,
        },
        '&.contrast.Mui-disabled ': {
          color: alpha(theme.palette.common.white, 0.6),
        },
      }),
    },
  },
  MuiTypography: {
    styleOverrides: {
      body2: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        textUnderlineOffset: '2px',
      },
    },
  },
  MuiPaper: {
    styleOverrides: {
      elevation0: {
        boxShadow: 'none',
      },
      elevation: {
        boxShadow: '0px 2px 8px -1px rgba(0, 0, 0, 0.2)',
        borderRadius: themeScale.borderRadius,
      },
      elevation1: {
        boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.1)',
      },
      outlined: {
        border: 'none',
        boxShadow: `0px 2px 4px -1px rgba(0, 0, 0, 0.1)`,
        borderRadius: themeScale.borderRadius,
        '&.hovered': {
          boxSizing: 'content-box',
        },
        '&.hovered:hover': {
          boxShadow: `0px 3px 5px -1px rgba(0, 0, 0, 0.2)`,
        },
      },
      root: {
        '.MuiTableBody-root tr:last-child .MuiTableCell-root': {
          borderBottom: 'none',
        },
      },
    },
  },
  MuiDialog: {
    styleOverrides: {
      root: {
        '.MuiPaper-rounded': {
          borderRadius: themeScale.borderRadius,
        },
      },
    },
  },
  MuiDialogTitle: {
    defaultProps: {
      variant: 'h2',
    },
  },
  MuiDialogActions: {
    styleOverrides: {
      root: {
        padding: '16px 24px',
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      root: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
    },
  },
  MuiTableHead: {
    styleOverrides: {
      root: ({ theme }) => ({
        '& th, & td': {
          background: theme.palette.background.paper,
        },
      }),
    },
  },
  MuiTableBody: {
    styleOverrides: {
      root: {
        '&:not(.noOverrides) .MuiTableRow-root:not(.no-height)': {
          height: themeScale.tableRowHeight,
        },
      },
    },
  },
  MuiTableRow: {
    styleOverrides: {
      root: ({ theme }) => ({
        '&.no-border .MuiTableCell-root:not(.with-border)': {
          borderBottom: 'none',
        },
        '&.space-top .MuiTableCell-root': {
          paddingTop: '20px',
        },
        '&.space-bottom .MuiTableCell-root': {
          paddingBottom: '20px',
        },
        '&.expandable:hover .MuiTableCell-root': {
          background: theme.palette.background.default,
          cursor: 'pointer',
        },
      }),
    },
  },
  MuiTableCell: {
    styleOverrides: {
      root: {
        borderBottomColor: 'rgb(0 0 0 / 8%)',
      },
    },
  },
  MuiDivider: {
    styleOverrides: {
      root: {
        borderColor: 'rgb(0 0 0 / 8%)',
      },
    },
  },
  MuiAutocomplete: {
    styleOverrides: {
      groupLabel: ({ theme }) => ({
        color: theme.palette.text.disabled,
      }),
      root: ({ theme }) => ({
        '.MuiInputBase-adornedStart ': {
          paddingBottom: '4px',
          paddingTop: '24px',
        },
        '&.MuiAutocomplete-searchBox': {
          '&.MuiAutocomplete-hasValue': {
            '.MuiInputBase-root.MuiInputBase-sizeSmall:not(.Mui-focused)': {
              backgroundColor: theme.palette.highlight.main,

              '.MuiOutlinedInput-notchedOutline': {
                borderColor: theme.palette.highlight.main,
              },
            },
          },
          '.MuiInputBase-root.MuiInputBase-sizeSmall': {
            paddingTop: '9px',
            paddingLeft: '16px',
            paddingBottom: '11px',
            background: theme.palette.background.paper,
          },
          '.MuiInputAdornment-positionStart': {
            marginTop: '3px',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: theme.palette.divider,
            transition: 'border-color .2s',
          },
          '.MuiInputBase-inputSizeSmall.MuiInputBase-inputAdornedStart.MuiInputBase-inputAdornedEnd':
            {
              maxWidth: 'calc(100% - 70px)',
            },
        },
      }),
    },
  },
  MuiAvatar: {
    styleOverrides: {
      root: {
        backgroundColor: '#F9D9AA',
        borderRadius: themeScale.avatarRadius,
        '& img': {
          objectPosition: 'top',
        },
      },
    },
  },
  MuiChip: {
    styleOverrides: {
      root: {
        borderRadius: '10px',
      },
    },
  },
  MuiSvgIcon: {
    styleOverrides: {
      colorSecondary: ({ theme }) => ({
        color: theme.palette.text.secondary,
      }),
    },
  },
  MuiListItemButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        fontWeight: 500,
        minHeight: 48,
        marginBottom: 4,
        '& .MuiSvgIcon-root': {
          color: theme.palette.text.secondary,
          transition: 'all 0.2s linear',
        },
        '& .MuiTypography-body1': {
          lineHeight: '120%',
        },
        '&:hover': {
          background: theme.palette.highlight.neutral,
          '& .MuiSvgIcon-root': {
            transform: 'scale(1.1)',
            color: theme.palette.brand.primary,
          },
        },
        '&.Mui-selected, &.Mui-selected:hover, &.Mui-selected:focus': {
          background: theme.palette.brand.highlight,
          color: theme.palette.brand.primary,
          '& .MuiSvgIcon-root': {
            color: theme.palette.brand.primary,
          },
        },
      }),
    },
  },
  MuiToggleButton: {
    styleOverrides: {
      root: ({ theme }) => ({
        background: theme.palette.action.hover,
        border: 'none',
        '&.Mui-selected, &.Mui-selected:hover': {
          background: theme.palette.highlight.main,
        },
        '&.Mui-disabled': {
          background: theme.palette.action.disabledBackground,
          border: 'none',
        },
        '&.Mui-selected.Mui-disabled': {
          background: theme.palette.action.disabled,
        },
      }),
    },
  },
  MuiAlert: {
    styleOverrides: {
      message: {
        fontSize: themeScale.fontSizeRegular,
      },
      standardInfo: ({ theme }) => ({
        background: theme.palette.action.disabledBackground,
        color: theme.palette.text.primary,
      }),
    },
  },
  MuiTooltip: {
    styleOverrides: {
      tooltip: {
        fontSize: themeScale.fontSizeSmall,
        lineHeight: '130%',
      },
    },
  },
  MuiBreadcrumbs: {
    styleOverrides: {
      separator: ({ theme }) => ({
        color: theme.palette.text.disabled,
      }),
    },
  },
  MuiCircularProgress: {
    styleOverrides: {
      colorSecondary: ({ theme }) => ({
        color: theme.palette.brand.primary,
      }),
    },
  },
};
