import React, { Fragment, PropsWithChildren } from 'react';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { fadeInDuration } from '../../theme';
import { ServerStatusCode } from '../../constants';
import { Loader } from '../loader/loader';
import { FallbackComponent } from '../fallbackComponent/FallbackComponent';
import { SxProps, Theme } from '@mui/material';

interface Props {
  isLoading?: boolean;
  showContent?: boolean;
  hideOverflowX?: boolean;
  statusCode?: ServerStatusCode;
  maxWidth?: string;
  sx?: SxProps<Theme> | undefined;
}

export const PageWrapper: React.FC<PropsWithChildren<Props>> = ({
  children,
  isLoading = false,
  showContent,
  statusCode,
  hideOverflowX = false,
  maxWidth,
  sx,
}) => {
  const content = statusCode ? (
    <FallbackComponent statusCode={statusCode} />
  ) : (
    children
  );

  return (
    <Fragment>
      {isLoading && <Loader withBg />}
      <Fade
        in={!isLoading || showContent}
        timeout={fadeInDuration}
        mountOnEnter
      >
        <Box
          data-testid="PageWrapper"
          sx={{
            height: '100%',
            pt: 4,
            pb: 4,
            marginLeft: 'auto',
            marginRight: 'auto',
            ...(hideOverflowX ? { overflowX: 'hidden' } : {}),
            ...(maxWidth ? { maxWidth } : {}),
            ...sx,
          }}
        >
          {content}
        </Box>
      </Fade>
    </Fragment>
  );
};
