import React, { useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ReportType } from '../../types/reports';
import { formatYYYYMMDateToMMMMYString, plural, is } from '../../utils';
import { KpiStatus, REPORT_KEYS } from '../../constants';
import { priceFormat } from '../../components/tableCellRenders';
import { ActionTakerBadge } from '../../components/actionTaker/ActionTaker';
import { getFinalBonus } from './Reports.utils';
import { useTheme } from '@mui/material';
import { capitalizeFirstLetter } from '../globalTracker/utils';

interface Props {
  reports: ReportType[];
}

const ReportsTable: React.FC<Props> = ({ reports }) => {
  const { palette, spacing } = useTheme();

  const apporvedReports = reports.filter(
    (report) => report.status === KpiStatus.Approved,
  );
  const unapporvedReports = reports.filter(
    (report) => report.status !== KpiStatus.Approved,
  );

  const hasTrueUp = useMemo(() => {
    return !!(reports || []).find(({ trueup }) => !is.nullOrUndefined(trueup));
  }, [reports]);

  const renderFinalBonus = (report: ReportType) => {
    if (!hasTrueUp) {
      return null;
    }

    const finalBonus = getFinalBonus(report);

    return (
      <>
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {priceFormat({ value: report.trueup })}
        </TableCell>
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {priceFormat({ value: finalBonus })}
        </TableCell>
      </>
    );
  };

  const renderRow = (report: ReportType, index: number) => (
    <TableRow
      key={`${report.fullName}_${index}`}
      hover={true}
      className="no-height"
    >
      <TableCell sx={{ pl: 3, maxWidth: '180px' }}>
        {report.department.replaceAll('&amp;', '&')}
      </TableCell>
      <TableCell>{report.bonusType}</TableCell>
      <TableCell>{report.fullName}</TableCell>
      <TableCell>{report.metricName}</TableCell>
      <TableCell sx={{ whiteSpace: 'nowrap' }}>
        {formatYYYYMMDateToMMMMYString(report.period)}
      </TableCell>
      {report.payout !== null &&
      report.payout !== undefined &&
      report.status === KpiStatus.Approved ? (
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {priceFormat({ value: report.payout })}
        </TableCell>
      ) : (
        <TableCell align={'right'} sx={{ fontWeight: '500' }}>
          {report.actionTaker && (
            <ActionTakerBadge
              status={report.status}
              actionTaker={report.actionTaker}
            />
          )}
        </TableCell>
      )}

      {renderFinalBonus(report)}

      <TableCell>{report.location}</TableCell>
      <TableCell>{capitalizeFirstLetter(report.frequency)}</TableCell>
      <TableCell sx={{ pr: 3 }}>{report.city || 'N/A'}</TableCell>
    </TableRow>
  );

  const trueUpHeader = hasTrueUp ? (
    <>
      <TableCell align={'right'} sx={{ pr: 4.5 }}>
        {REPORT_KEYS.trueUp}
      </TableCell>
      <TableCell align={'right'} sx={{ pr: 4.5 }}>
        {REPORT_KEYS.finalBonus}
      </TableCell>
    </>
  ) : null;

  return (
    <TableContainer sx={{ overflowX: 'initial' }}>
      <Table stickyHeader size="small" aria-label="reports-table">
        <TableHead>
          <TableRow>
            <TableCell sx={{ pl: 3 }}>{REPORT_KEYS.department}</TableCell>
            <TableCell>{REPORT_KEYS.bonusType}</TableCell>
            <TableCell>{REPORT_KEYS.fullName}</TableCell>
            <TableCell>{REPORT_KEYS.metricName}</TableCell>
            <TableCell>Period</TableCell>
            <TableCell align={'right'} sx={{ pr: 4.5 }}>
              {REPORT_KEYS.bonus}
            </TableCell>

            {trueUpHeader}

            <TableCell>{REPORT_KEYS.location}</TableCell>
            <TableCell>{REPORT_KEYS.frequency}</TableCell>
            <TableCell sx={{ pr: 3 }}>{REPORT_KEYS.city}</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {!!unapporvedReports.length && (
            <>
              <TableRow className="no-height">
                <TableCell
                  colSpan={11}
                  sx={{
                    height: 48,
                    fontWeight: 500,
                    padding: `0 ${spacing(3)}`,
                    border: 'none',
                    color: palette.error.main,
                    background: palette.highlight.error,
                  }}
                >
                  {`${unapporvedReports.length} metric${plural(
                    unapporvedReports.length,
                  )} need results and approval`}
                </TableCell>
              </TableRow>
              {unapporvedReports.map(renderRow)}
              {!!apporvedReports.length && (
                <TableRow className="no-height">
                  <TableCell
                    colSpan={11}
                    sx={{
                      height: 48,
                      fontWeight: 500,
                      padding: `0 ${spacing(3)}`,
                      border: 'none',
                      color: palette.success.main,
                      background: palette.highlight.success,
                    }}
                  >
                    Approved
                  </TableCell>
                </TableRow>
              )}
            </>
          )}
          {apporvedReports.map(renderRow)}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReportsTable;
