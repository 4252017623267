import {
  AccountOverview,
  AccountStatus,
  CustomLinks,
  SortOrder,
} from '../../types';
import { accountsStoreKey } from './accounts.const';

export interface AccountsState {
  accounts: AccountOverview[];
  loading: boolean;
  error?: null | {
    message: string;
  };

  sortBy: SortAccountsBy;
  sortOrder: SortOrder;

  groupBy: GroupAccountsBy;

  uniqueFilterLists: {
    pdms: { value: string; label: string }[];
    dms: { value: string; label: string }[];
    cps: { value: string; label: string }[];
  };
  isUniqueFilterListsDefined?: boolean;

  dateFilterValue: string | null;
  dateFilterValueLocal: string | null;
  accountListFetchErrorMessage?: string | null;
  filters: AccountsPageFilters;
}

export interface AppStateWithAccountsData {
  [accountsStoreKey]: AccountsState;
}

export enum SortAccountsBy {
  AccountName = 'Account name',
  Margin = 'Margin',
  Headcount = 'Headcount',
}

export enum FilterAccountsBy {
  PortfolioDeliveryManager = 'PDM',
  DeliveryManager = 'DM',
  ClientPartner = 'CP',
}

export enum GroupAccountsBy {
  AccountName = 'Account Name',
  ClientPartner = 'Client partner',
  PortfolioDeliveryManager = 'PDM',
  DeliveryManager = 'DM',
}

export const sortAccountsByOptions = Object.entries(SortAccountsBy).map(
  ([, value]) => ({
    label: value,
    value,
  }),
);

export const filterAccountsOptions = Object.entries(FilterAccountsBy).map(
  ([, value]) => ({
    label: value,
    value,
  }),
);

export const groupAccountsOptions = Object.entries(GroupAccountsBy).map(
  ([, value]) => ({
    label: value,
    value,
  }),
);

export interface PeriodFilteringDto {
  from: string;
  to: string;
}

export interface GetAccountsData {
  fetchAllMembers?: boolean | false;
  departmentFilters?: AccountsQueryFilters;
  periodFiltering?: PeriodFilteringDto;
}

export interface AccountsQueryFilters {
  pdmId?: string[];
  dmId?: string[];
  cpId?: string[];
  status?: AccountStatus;
}

export interface AccountsSortPayloadType {
  sortBy: SortAccountsBy;
  sortOrder?: SortOrder;
}

export interface AccountsFilterPayloadType {
  filterBy: FilterAccountsBy;
}

export interface AccountsPageFilters {
  accountStatus: AccountStatus | null;
  filterValues: string[];
  filterBy: FilterAccountsBy;
}

export interface saveLinkPayloadType {
  accountId: string;
  sharedDriveLink?: string | null;
  customLinks?: CustomLinks[] | [];
  compensationToolLink?: string | null;
}
