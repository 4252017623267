import React, { FC, useState, useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Sidebar from '../../features/sidebar/Sidebar';
import { NoConnection } from '../noConnection/noConnection';
import useOnlineStatus from '../../hooks/useOnlineStatus';
import { AuthService } from '../../services/auth-service';
import { getCustomEmail } from '../../hooks/useAuth';
import { collapsedDrawerWidth, drawerWidth } from '../../theme';

const MainPage: FC = () => {
  const [accessToken, setAccessToken] = useState<string | null>(
    localStorage.getItem('accessToken'),
  );
  const [tokenUpdated, setTokenUpdated] = useState('');
  const isOffline = useOnlineStatus();

  useEffect(() => {
    const handleStorageChange = () => {
      setAccessToken(localStorage.getItem('accessToken'));
      const customEmail = getCustomEmail();

      if (customEmail) {
        AuthService.customLogin(customEmail)?.then(() => {
          const accessToken = AuthService.getToken();

          if (accessToken) {
            setAccessToken(accessToken);
            setTokenUpdated(accessToken);
          }
        });
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [accessToken]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns: `${drawerWidth}px 1fr`,
        gap: '10px',
        '@media(max-width:1280px)': {
          gridTemplateColumns: `${collapsedDrawerWidth}px 1fr`,
          gap: '5px',
        },
      }}
    >
      <Sidebar />
      <Container
        key={tokenUpdated}
        maxWidth={false}
        component="main"
        disableGutters={true}
        sx={{
          bgcolor: 'background.default',
          minHeight: '100vh',
          position: 'relative',
          paddingLeft: 0,
          paddingRight: 0,
          marginLeft: 0,
          marginRight: 0,
        }}
      >
        {isOffline ? <NoConnection /> : <Outlet />}
      </Container>
    </Box>
  );
};

export default MainPage;
