import React from 'react';

import * as _ from 'lodash';
import { MarginHighlight } from '../../../../types';
import { Info } from '../Info/Info';
import { Margin } from '../Margin/Margin';

export const GrossMarginSummary = ({
  marginHighlights,
  grossMargin,
  header,
}: {
  marginHighlights?: MarginHighlight[];
  grossMargin?: number;
  header?: string;
}) => {
  if (_.isNil(grossMargin) || _.isNil(marginHighlights)) return <></>;

  return (
    <Info title={header ?? 'Gross margin'}>
      <Margin marginHighlights={marginHighlights} value={grossMargin} />
    </Info>
  );
};
