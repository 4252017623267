import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { PageWrapper } from '../../components';
import { useAppSelector, useAppDispatch } from '../../redux/hooks';
import {
  accountsSlice,
  fetchAccounts,
  FilterAccountsBy,
  sumUpAccountMembers,
  accountsSelectors,
  GetAccountsData,
  selectAccountsPageFilters,
  AccountsPageFiltersInitialState,
} from '../../redux/accounts';
import { accountSlice } from '../../redux/account';
import { AccountsTable } from './AccountsTable/AccountsTable';
import { userDataSelectors } from '../../redux/userData';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import FilterListIcon from '@mui/icons-material/FilterList';
import { PageTitle } from '../../utils/pageTitles';
import { useTitle } from '../../hooks/useTitle';
import CalculateOutlinedIcon from '@mui/icons-material/CalculateOutlined';
import { AccountReportsPopper } from './AccountReportsPopper/AccountReportsPopper';
import { Info } from './BudgetsHeader/Info/Info';
import { CustomNumber } from './BudgetsHeader/Number/Number';
import { AccountOverview, AccountStatus } from '../../types';
import { GrossMarginSummary } from './BudgetsHeader/GrossMarginSummary/GrossMarginSummary';
import WorkOffOutlinedIcon from '@mui/icons-material/WorkOffOutlined';
import { FilterDrawer } from './AccountsFilters/AccountsFilters';
import { isEqual } from 'lodash';

export const Budgets = () => {
  const setPageTitle = useTitle();

  useEffect(() => {
    setPageTitle(PageTitle.portfolio);
  }, [setPageTitle]);

  const dispatch = useAppDispatch();
  const loading = useAppSelector(accountsSelectors.getLoading);
  const accounts = useAppSelector(accountsSelectors.getProcessedAccounts);
  const accountsPageFilters = useAppSelector(selectAccountsPageFilters);
  const { filterBy, ...accountsPageFiltersWithoutFilterBy } =
    accountsPageFilters;
  const { filterBy: _, ...accountsPageFiltersInitialStateWithoutFilterBy } =
    AccountsPageFiltersInitialState;
  const filterValue = accountsPageFilters.filterValues;

  const user = useAppSelector(userDataSelectors.getUserData);
  const dateFilterValue = useAppSelector(accountsSelectors.getDateFilterValue);

  const errMessage = useAppSelector(
    accountsSelectors.getAccountsListFetchErrorMessage,
  );
  const isCLevelUser = user?.permissions.seesAllAccounts;
  const [isDrawerOpen, setDrawerOpen] = useState<boolean>(false);

  const canGenerateMarginReports = useMemo(
    () =>
      user?.permissions.hasAccounts &&
      user?.permissions.isBillRateManager &&
      user.permissions.isCompensationManager,
    [user],
  );

  useEffect(() => {
    dispatch(
      fetchAccounts(
        getFetchAccountsParams({
          filterValue,
          filterBy,
          accountStatus: accountsPageFilters.accountStatus,
          dateFilterValue,
        }),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    dispatch,
    filterValue,
    accountsPageFilters.accountStatus,
    dateFilterValue,
  ]);

  useEffect(() => {
    if (accountsPageFilters.filterValues.length > 0) {
      dispatch(
        accountsSlice.actions.setAccountsPageFilters({ filterValues: [] }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, accountsPageFilters.filterBy]);

  useEffect(() => {
    dispatch(accountSlice.actions.cleanDateFilterValues());
  });

  const handleFiltersOnClick = useCallback(() => {
    setDrawerOpen(true);
  }, [setDrawerOpen]);

  const handleFiltersOnClose = useCallback(() => {
    setDrawerOpen(false);
  }, [setDrawerOpen]);

  const resetFilters = useCallback(() => {
    dispatch(
      accountsSlice.actions.setAccountsPageFilters({
        filterBy: FilterAccountsBy.PortfolioDeliveryManager,
      }),
    );
    dispatch(accountsSlice.actions.cleanDateFilterValues());

    dispatch(
      fetchAccounts(
        getFetchAccountsParams({
          filterValue: AccountsPageFiltersInitialState.filterValues,
          filterBy: AccountsPageFiltersInitialState.filterBy,
          accountStatus: AccountsPageFiltersInitialState.accountStatus,
          dateFilterValue: null,
        }),
      ),
    );
    dispatch(accountsSlice.actions.resetAccountsPageFilters());
  }, [dispatch]);

  const isFiltersActive = !isEqual(
    accountsPageFiltersInitialStateWithoutFilterBy,
    accountsPageFiltersWithoutFilterBy,
  );

  return (
    <PageWrapper
      isLoading={loading}
      sx={{
        pt: 0,
        pb: 0,
      }}
    >
      <FilterDrawer
        isFiltersActive={isFiltersActive}
        isOpen={isDrawerOpen}
        onClose={handleFiltersOnClose}
        resetFilters={resetFilters}
      />

      {!accounts.length && !errMessage && (
        <Box
          display="flex"
          justifyContent="center"
          alignContent="center"
          sx={{
            height: '90%',
            backgroundColor: 'white',
            display: 'grid',
            verticalAlign: 'middle',
          }}
        >
          <Typography variant="h1" mx="2" color="darkslategray">
            {"You don't have any accounts assigned to you"}
          </Typography>
        </Box>
      )}

      {!!accounts.length && !errMessage && (
        <BudgetsHeaderForList
          accounts={accounts}
          handleFiltersOnClick={handleFiltersOnClick}
          errMessage={errMessage}
          isFiltersActive={isFiltersActive}
          dateFilterValue={dateFilterValue}
          resetFilters={resetFilters}
          canGenerateMarginReports={canGenerateMarginReports}
        />
      )}
      {!(!accounts.length && !errMessage) && (
        <>
          <AccountsTable
            accounts={accounts}
            isCLevelUser={isCLevelUser ?? false}
          />
          {accountsPageFilters.accountStatus === AccountStatus.Active && (
            <Stack
              sx={{
                mb: '40px',
                bottom: 0,
                padding: '16px 0px 16px 16px',
                bgcolor: 'background.default',
                width: '100%',
              }}
            >
              <Stack flexDirection="row" gap="8px">
                <WorkOffOutlinedIcon color="action" />
                <Button
                  variant="text"
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                    color: 'black',
                    fontSize: '14px',
                    padding: '0',
                    background: 'none !important',
                  }}
                  onClick={() => {
                    dispatch(
                      accountsSlice.actions.setAccountsPageFilters({
                        accountStatus: null,
                      }),
                    );
                  }}
                >
                  Show closed accounts
                </Button>
              </Stack>
            </Stack>
          )}
        </>
      )}
    </PageWrapper>
  );
};

interface BudgetFilterHeaderProps {
  accounts: AccountOverview[];
  handleFiltersOnClick: () => void;
  canGenerateMarginReports?: boolean;
  errMessage?: string | null;
  dateFilterValue: string | null;
  isFiltersActive: boolean;
  resetFilters: () => void;
}

const BudgetsHeaderForList = ({
  accounts,
  handleFiltersOnClick,
  canGenerateMarginReports,
  errMessage,
  dateFilterValue,
  isFiltersActive,
  resetFilters,
}: BudgetFilterHeaderProps) => {
  const reportsAnchor = React.useRef<HTMLButtonElement | null>(null);
  const [isReportsPopperOpen, setReportsPopperOpen] = useState<boolean>(false);

  const toggleReportsPopper = useCallback(() => {
    setReportsPopperOpen(!isReportsPopperOpen);
  }, [setReportsPopperOpen, isReportsPopperOpen]);

  const handlePopperClose = useCallback(() => {
    setReportsPopperOpen(false);
  }, []);

  const flagsCount = useMemo(() => {
    return accounts?.reduce(
      (sum: number, obj: AccountOverview) =>
        sum + (obj?.flagsCount.red ?? 0) + (obj?.flagsCount.yellow ?? 0),
      0,
    );
  }, [accounts]);

  const positionsCount = useMemo(() => {
    return accounts?.reduce(
      (sum: number, obj: AccountOverview) => sum + (obj?.openPositions ?? 0),
      0,
    );
  }, [accounts]);

  const projectCount = useMemo(() => {
    return accounts?.reduce(
      (sum: number, obj: any) => sum + obj.projects?.length,
      0,
    );
  }, [accounts]);

  const peopleCount = useMemo(() => {
    return accounts?.reduce(
      (sum: number, obj: any) => sum + sumUpAccountMembers(obj),
      0,
    );
  }, [accounts]);

  if (!accounts?.length) {
    return <p>No accounts info found!</p>;
  }

  return (
    <Box
      sx={(theme) => ({
        display: 'flex',
        backgroundColor: '#fff',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        height: theme.spacing(12),
        padding: theme.spacing(1.5, 2.5),
        gap: theme.spacing(4),
        borderRadius: theme.spacing(1, 1, 0, 0),
      })}
    >
      <GrossMarginSummary
        marginHighlights={accounts[0]?.marginHighlights}
        grossMargin={accounts[0]?.portfolioMargins?.grossMargin}
        header="Portfolio margin"
      />
      <Info title="Accounts">
        <CustomNumber value={accounts.length}>{accounts.length}</CustomNumber>
      </Info>
      <Info title="Teams">
        <CustomNumber value={projectCount}>
          {projectCount ? projectCount : 0}
        </CustomNumber>
      </Info>
      <Info title="Headcount">
        <CustomNumber value={peopleCount}>
          {peopleCount ? peopleCount : 0}
        </CustomNumber>
      </Info>
      <Info title="Positions">
        <CustomNumber value={positionsCount}>
          {positionsCount ? positionsCount : 0}
        </CustomNumber>
      </Info>
      <Info title="Flags">
        <CustomNumber value={flagsCount}>
          {flagsCount ? flagsCount : 0}
        </CustomNumber>
      </Info>
      <Box display="flex" alignItems="center" ml="auto">
        <Button
          variant="text"
          onClick={resetFilters}
          sx={{
            ...(!(isFiltersActive || dateFilterValue !== null) && {
              visibility: 'hidden',
            }),
            mr: '8px',
          }}
        >
          <RemoveCircleOutlineIcon fill="#448aff" />
          &nbsp; Reset all
        </Button>

        {/* Account reports button */}
        {!!canGenerateMarginReports && !errMessage && accounts.length && (
          <>
            <Button
              variant="text"
              sx={{
                color: 'black',
                mr: '8px',
              }}
              onClick={(e) => {
                e.stopPropagation();
                toggleReportsPopper();
              }}
              ref={reportsAnchor}
            >
              <CalculateOutlinedIcon />
              &nbsp; Reports
            </Button>
            <AccountReportsPopper
              open={isReportsPopperOpen}
              handlePopperClose={handlePopperClose}
              anchorEl={reportsAnchor}
            />
          </>
        )}

        {/* Filter button */}
        <Button
          sx={{ color: 'black', mr: '8px' }}
          variant="text"
          onClick={handleFiltersOnClick}
        >
          <FilterListIcon />
          &nbsp; Filters
        </Button>
      </Box>
    </Box>
  );
};

const getFetchAccountsParams = ({
  filterValue,
  filterBy,
  dateFilterValue,
  accountStatus,
}: {
  filterValue: string[];
  filterBy: FilterAccountsBy;
  dateFilterValue: string | null;
  accountStatus: AccountStatus | null;
}): GetAccountsData => {
  const isResponsiblePeople = filterValue.length > 0;
  return {
    fetchAllMembers: true,
    ...((isResponsiblePeople || accountStatus) && {
      departmentFilters: {
        ...(filterBy === FilterAccountsBy.DeliveryManager &&
          isResponsiblePeople && {
            dmId: filterValue,
          }),
        ...(filterBy === FilterAccountsBy.PortfolioDeliveryManager &&
          isResponsiblePeople && {
            pdmId: filterValue,
          }),
        ...(filterBy === FilterAccountsBy.ClientPartner &&
          isResponsiblePeople && {
            cpId: filterValue,
          }),
        ...(accountStatus && {
          status: accountStatus,
        }),
      },
    }),
    ...(dateFilterValue && {
      periodFiltering: {
        from: dateFilterValue,
        to: dateFilterValue,
      },
    }),
  };
};
