import React, { useCallback, useState } from 'react';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import NavigationLink from './NavigationLink';
import { useAppSelector } from '../../redux/hooks';
import { userDataSelectors } from '../../redux/userData';
import HowToRegRoundedIcon from '@mui/icons-material/HowToRegRounded';
import ContactsRoundedIcon from '@mui/icons-material/ContactsRounded';
import AddchartRoundedIcon from '@mui/icons-material/AddchartRounded';
import SimCardDownloadOutlinedIcon from '@mui/icons-material/SimCardDownloadOutlined';
import EventAvailableRoundedIcon from '@mui/icons-material/EventAvailableRounded';
import WorkspacePremiumOutlinedIcon from '@mui/icons-material/WorkspacePremiumOutlined';

import MonitorHeartOutlinedIcon from '@mui/icons-material/MonitorHeartOutlined';
import { paths } from '../../constants/paths';
import SecurityIcon from '@mui/icons-material/Security';
import {
  ListItemButton,
  ListItemText,
  ListItemIcon,
  Divider,
  Tooltip,
} from '@mui/material';
import {
  BugReportOutlined,
  KeyboardArrowUpRounded,
  MenuRounded,
} from '@mui/icons-material';
import ReportIssueModal from '../../features/reportIssueModal/ReportIssueModal';

export const NAVIGATION_TEST_ID = 'navigation-test-id';

interface NavigationProps {
  open: boolean;
}

const Navigation: React.FC<NavigationProps> = ({ open }: NavigationProps) => {
  const user = useAppSelector(userDataSelectors.getUserData);
  const [isSecondaryNavOpen, setNavOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

  const handleSecondaryOptionsToggle = useCallback(() => {
    setNavOpen(!isSecondaryNavOpen);
  }, [isSecondaryNavOpen, setNavOpen]);

  const handleToggleModal = useCallback(() => {
    setIsModalOpen(!isModalOpen);
  }, [isModalOpen, setIsModalOpen]);

  if (!user) {
    return null;
  }

  const {
    canViewListOfContractors,
    canManageKpiDefinitions,
    canSubmitKpiValues,
    canApproveKpiValues,
    canGenerateReport,
    canViewMyMetrics,
    hasAccounts,
    isBillRateManager,
    isCompensationManager,
    isFinance,
  } = user.permissions;

  const hasBonusesAccess =
    canViewMyMetrics ||
    canViewListOfContractors ||
    canSubmitKpiValues ||
    canApproveKpiValues ||
    canGenerateReport;

  const hasMarginTrackerAccess =
    hasAccounts && (isBillRateManager || isCompensationManager);

  return (
    <List
      data-testid={NAVIGATION_TEST_ID}
      sx={{ mb: 4, ml: 1, mr: open ? 2 : 1 }}
    >
      {hasBonusesAccess && (
        <>
          <Typography
            variant="body2"
            ml={2.5}
            mt={2}
            mb={0.5}
            sx={{ display: `${open ? 'block' : 'none'}` }}
          >
            Bonuses
          </Typography>
          {canViewMyMetrics && (
            <NavigationLink
              to={paths.myMetrics}
              icon={<HowToRegRoundedIcon />}
              title="My metrics"
              open={open}
            />
          )}
          {canViewListOfContractors && (
            <NavigationLink
              to={paths.contractors}
              icon={<ContactsRoundedIcon />}
              title="Teams"
              open={open}
            />
          )}
          {canSubmitKpiValues && (
            <NavigationLink
              to={paths.submissions}
              icon={<AddchartRoundedIcon />}
              title="Monthly results"
              open={open}
            />
          )}
          {canApproveKpiValues && (
            <NavigationLink
              to={paths.approvals}
              icon={<EventAvailableRoundedIcon />}
              title="Approvals"
              open={open}
            />
          )}
          {canGenerateReport && (
            <NavigationLink
              to={paths.reports}
              icon={<SimCardDownloadOutlinedIcon />}
              title="Reports"
              open={open}
            />
          )}
          {isFinance && (
            <NavigationLink
              to={paths.clientInvoice}
              icon={<SimCardDownloadOutlinedIcon />}
              title="Client Invoices"
              open={open}
            />
          )}
        </>
      )}
      {canManageKpiDefinitions && (
        <>
          <Typography
            variant="body2"
            ml={2.5}
            mt={2}
            mb={0.5}
            sx={{ display: `${open ? 'block' : 'none'}` }}
          >
            Administration
          </Typography>

          <Divider
            component="li"
            sx={{ display: `${open ? 'none' : 'block'}` }}
          />
          <NavigationLink
            to={paths.kpiDefinitions}
            icon={<WorkspacePremiumOutlinedIcon />}
            title="KPI definitions"
            open={open}
          />
        </>
      )}
      {hasMarginTrackerAccess && (
        <>
          <Typography
            variant="body2"
            ml={2.5}
            mt={2}
            mb={0.5}
            sx={{ display: `${open ? 'block' : 'none'}` }}
          >
            Portfolio
          </Typography>

          <Divider
            component="li"
            sx={{ display: `${open ? 'none' : 'block'}` }}
          />
          <NavigationLink
            to={paths.portfolio}
            icon={<MonitorHeartOutlinedIcon />}
            title={'My portfolio'}
            open={open}
          />
        </>
      )}

      <Divider component="li" sx={{ display: `${open ? 'none' : 'block'}` }} />

      <ListItemButton
        onClick={handleSecondaryOptionsToggle}
        sx={{
          pl: 0,
          pt: 1,
          pb: 1,
          borderRadius: 1,
          pr: 0,
          justifyContent: 'center',
        }}
      >
        <ListItemIcon sx={{ justifyContent: 'center' }}>
          {isSecondaryNavOpen ? <KeyboardArrowUpRounded /> : <MenuRounded />}
        </ListItemIcon>
        <ListItemText
          primary="More"
          primaryTypographyProps={{
            mb: '2px',
          }}
          secondary="Report issue, terms"
          sx={{ display: `${open ? 'block' : 'none'}` }}
        />
      </ListItemButton>
      {isSecondaryNavOpen && (
        <>
          <ListItemButton
            onClick={handleToggleModal}
            sx={{
              pl: 0,
              pt: 1,
              pb: 1,
              borderRadius: 1,
              pr: open ? 2 : 0,
              justifyContent: open ? 'flex-start' : 'center',
            }}
          >
            {open && (
              <>
                <ListItemIcon sx={{ justifyContent: 'center' }}>
                  <BugReportOutlined />
                </ListItemIcon>
                <ListItemText primary="Report issue" />
              </>
            )}
            {!open && (
              <Tooltip title="Report issue" placement="right">
                <ListItemIcon sx={{ justifyContent: 'center' }}>
                  <BugReportOutlined />
                </ListItemIcon>
              </Tooltip>
            )}
          </ListItemButton>

          {isModalOpen && (
            <ReportIssueModal
              isModalOpen={isModalOpen}
              handleCloseModal={handleToggleModal}
            />
          )}
          <NavigationLink
            to={paths.termsOfUse}
            icon={<SecurityIcon />}
            title={'Terms of use'}
            open={open}
          />
        </>
      )}
    </List>
  );
};

export default Navigation;
